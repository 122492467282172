<template>
  <button
    ref="buttonRef"
    class="fr-button"
    :class="{
      // secondary
      'fr-button--secondary': variant === 'secondary',
      // danger
      'fr-button--danger': variant === 'danger',
      // warning
      'fr-button--warning': variant === 'warning',
      // success
      'fr-button--success': variant === 'success',
      // link
      'fr-button--link': variant === 'link',
      // ghost
      'fr-button--ghost': variant === 'ghost',
      // outline
      'fr-button--outline': variant === 'outline',
      // Primary
      'fr-button--primary': !variant,
      // Sizes
      'text-xs font-normal': size === 'xs',
      'text-sm font-normal': size === 'sm',
      'text-base font-medium': size === 'md',
      'text-lg font-semibold': size === 'lg',
      'text-xl': size === 'xl',
      'text-2xl': size === '2xl'
    }"
    :disabled="disabledRef"
    @click="handleClick"
  >
    <template v-if="props.loading">
      <q-spinner-tail class="min-h-5 min-w-5" />
    </template>

    <template v-else-if="props.icon">
      <q-icon :name="props.icon" :size="size" />
    </template>
    <slot />
  </button>
</template>

<script setup lang="ts">
/**
 * ================================
 * Imports
 * ================================
 */
import gsap from 'gsap'

/**
 * ================================
 * Props
 * ================================
 */
const props = withDefaults(
  defineProps<{
    variant?:
      | 'outline'
      | 'secondary'
      | 'danger'
      | 'warning'
      | 'success'
      | 'link'
      | 'ghost'
      | string
      | null
    type?: 'button' | 'submit' | 'reset'
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
    icon?: string | null
    loading?: boolean
    disabled?: boolean
    to?: string | null
  }>(),
  {
    variant: null,
    type: 'button',
    size: 'xs',
    icon: null,
    loading: false,
    disabled: false,
    to: null
  }
)

const emits = defineEmits(['click'])

/**
 * ================================
 * Refs & Computed
 * ================================
 */

const buttonRef = ref<HTMLButtonElement | null>(null)

const scale = ref(1)

const disabledRef = computed(() => props.disabled)

/**
 * ================================
 * Functions
 * ================================
 */
const handleClick = (event: MouseEvent) => {
  const scene = gsap.timeline()

  scene.add(
    gsap.fromTo(
      event.currentTarget,
      {
        scale: 1,
        duration: 0.16
      },
      {
        scale: scale.value - 0.05,
        duration: 0.16,
        ease: 'power2.inOut'
      }
    )
  )

  scene.add(
    gsap.to(event.currentTarget, {
      scale: scale.value,
      duration: 0.22,
      ease: 'power2.inOut'
    })
  )

  scene.play()

  if (props.disabled) {
    event.preventDefault()
    return
  }

  if (props.to) {
    navigateTo(props.to)
    return
  }
  emits('click', event)
}

/**
 * ================================
 * Lifecycle Hooks
 * ================================
 */
onMounted(() => {
  if (buttonRef.value) {
    buttonRef.value.addEventListener('mouseleave', () => {
      scale.value = 1
      gsap.to(buttonRef.value, {
        scale: 1,
        duration: 0.15,
        ease: 'power1.inOut'
      })
    })

    buttonRef.value.addEventListener('mouseenter', () => {
      scale.value = 1.03
      gsap.to(buttonRef.value, {
        scale: scale.value,
        duration: 0.15,
        ease: 'power1.inOut'
      })
    })
  }
})
</script>

<style lang="sass">

.fr-button
  @apply relative inline-flex items-center justify-center
  @apply min-h-8 gap-2 whitespace-nowrap p-1.5 px-2.5 font-bold uppercase tracking-widest
  @apply transition-colors duration-300 ease-in-out

  &.fr-button--link
    @apply p-0 underline

  &.fr-button--outline
    @apply border

  &:disabled
    @apply cursor-not-allowed
/**
  * Neo
  */
.neo

  .fr-button
    @apply rounded-sm shadow-sm

  .fr-button--secondary
    @apply bg-fr-secondary-500 text-fr-gray-100 hover:bg-opacity-80

  .fr-button--danger
    @apply bg-fr-error text-fr-gray-100 hover:bg-opacity-80

  .fr-button--warning
    @apply bg-fr-warning text-fr-gray-900 hover:bg-opacity-80

  .fr-button--success
    @apply bg-fr-success text-fr-gray-100 hover:bg-opacity-80

  .fr-button--link
    @apply text-fr-gray-900 hover:text-opacity-80

  .fr-button--ghost
    @apply text-fr-gray-900 hover:bg-fr-gray-800/20

  .fr-button--outline
    @apply border-fr-gray-900 text-fr-gray-900 hover:bg-fr-gray-800/20

  .fr-button--primary
    @apply bg-fr-gray-900 text-fr-gray-100 hover:bg-opacity-80

  &.dark

    .fr-button--link
      @apply text-fr-gray-100 hover:text-opacity-80

    .fr-button--ghost
      @apply text-fr-gray-100 hover:bg-fr-gray-100/20

    .fr-button--outline
      @apply border-fr-gray-100 text-fr-gray-100 hover:bg-fr-gray-100/20

    .fr-button--primary
      @apply bg-fr-gray-100 text-fr-gray-900 hover:bg-opacity-80

/**
  * Chowey
  */
.chowey

  .fr-button
    @apply rounded-md shadow-md hover:shadow-lg
  .fr-button--secondary
    @apply bg-fr-secondary-600 text-fr-gray-100

  .fr-button--danger
    @apply bg-fr-error text-fr-gray-100

  .fr-button--warning
    @apply bg-fr-warning text-fr-gray-900

  .fr-button--success
    @apply bg-fr-success text-fr-gray-100

  .fr-button--link
    @apply text-fr-gray-900 shadow-none hover:text-opacity-80 hover:shadow-none

  .fr-button--ghost
    @apply text-fr-gray-900 shadow-none hover:bg-fr-gray-800/20

  .fr-button--outline
    @apply border-fr-gray-900 text-fr-gray-900 hover:bg-fr-gray-800/20

  .fr-button--primary
    @apply bg-fr-primary-500 text-fr-gray-100 hover:bg-opacity-80

  &.dark

    .fr-button--link
      @apply text-fr-gray-100 hover:text-opacity-80

    .fr-button--ghost
      @apply text-fr-gray-100 hover:bg-fr-gray-100/20

    .fr-button--outline
      @apply border-fr-gray-100 text-fr-gray-100 hover:bg-fr-gray-100/20

    .fr-button--primary
      @apply bg-fr-primary-600 text-fr-gray-100 hover:bg-opacity-80

  .fr-card-header

    .fr-button
      @apply text-fr-gray-100
    .fr-button--outline
      @apply border-fr-gray-100
    .fr-button--primary
      @apply bg-fr-gray-100 text-fr-gray-900
</style>
